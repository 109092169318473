import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import NavBar from "../../components/navigation-header";
import Footer from "../../components/footer";
import "../../styling/templates.css";
import "../../styling/home-page.css";
//import HomeVideo from "../../images/home-video.mp4";
import Logos1 from "./logos-1";
import Logos2 from "./logos-2";
import ParaSlider from "./para-slider";
import EmailForm from "./home-email-form";

export default function Home() {
  const [pointNumber, setPointNumber] = useState(551987466);
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the point number by 1 every second
      setPointNumber((prevPointNumber) => prevPointNumber + 1);
    }, 1000); // Update every second
    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // animation
  const [textboxRef, textInView] = useInView({
    triggerOnce: true,
  });

  const [session5, session5InView] = useInView({
    triggerOnce: true,
  });

  const [imageRef, imageInView] = useInView({
    triggerOnce: true,
  });

  const handleScrollToForm = () => {
    const formElement = document.getElementById("form-section");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // session - 1 - images
  const imageSources = [
    // "/images/home-page/GRS.png",
    "/images/home-page/ISO_45001-Logo.webp",
    "/images/home-page/ISO_14001_UKAS_2014.webp",
    "/images/home-page/ISO_9001_UKAS_2014_TCL_HR.webp",
    "/images/home-page/00011.webp",
  ];

  // session - 1 - pdf
  const pdfLink = [
    // "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/GSS-GRS-20210961_Eco_Spindles_(Private)_Limited_2023-11-08.pdf",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-45001-BPPL20_0296.00-2.en-1-1.pdf",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/14001-2022.pdf",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-9001-2015_2022.pdf",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/oeko-tex-ecospindles-certificate.pdf",
  ];

  return (
    <div>
      <NavBar />
      <div className="home-page-video-line">
        <video autoPlay loop muted className="home-video">
          <source src="/videos/home-video.webm" type="video/webm" />
        </video>
        <div className="home-page-video-section"></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic-home">
              TOWARDS A<br />
              BETTER PLANET
            </h1>
            <div className="pc">
              <p className="video-para">FROM 2010 TO TODAY, WE’VE RECYCLED</p>
            </div>
            <div className="mobile">
              <p className="video-para">
                FROM 2010 TO TODAY, WE’VE
                <br />
                RECYCLED
              </p>
            </div>
            <p className="number-loader">
              {pointNumber.toLocaleString()} BOTTLES
            </p>
          </div>
        </div>
      </div>

      <div>
        <center>
          <div className="green-box" ref={textboxRef}>
            <h2 className={`box-text ${textInView ? "fadeInUp" : ""}`}>
              WE CONVERT PLASTIC WASTE INTO VALUABLE PRODUCTS, AND REMAKE A
              CULTURE TOWARDS SUSTAINABILITY
            </h2>
          </div>
        </center>
      </div>

      {/* session - 1 */}
      <center>
        <div className="page-content-2">
          <div className="two-column-container">
            <div className="column">
              {/* Column 1 */}
              <div className="s1-para-1">
                <div className="s1-para-1-div">
                  <h2 className="topic">OUR STORY</h2>
                  <p className="para">
                    Our mission is simple: Reduce the carbon footprint we make
                    on our world. By using plastic waste to create high-grade
                    yarn and filaments, we strive to solve one of the greatest
                    environmental problems plaguing our planet. The products are
                    proof that we can live more sustainably with the help of
                    technology
                  </p>
                  <p className="para">
                    Eco Spindles further prepares for the future by hosting
                    several initiatives across Sri Lanka. The initiatives foster
                    eco-awareness in the populace while creating a recycling
                    infrastructure that will last for generations.
                  </p>
                  <div>
                    <div className="link-box-button">
                      <a
                        href="#initiatives"
                        className="box-link"
                        onClick={handleScrollToForm}
                      >
                        SUSTAINABILITY INITIATIVES
                      </a>
                    </div>
                  </div>
                  <h2 className="topic-2">QUALITY PRODUCTION PROCESS</h2>
                  <p className="para">
                    Eco Spindles maintains multiple layers of quality controls
                    supported by international quality accreditations across the
                    production process to ensure consistently high quality of
                    all outputs.
                  </p>
                  {/* <div className="pc-logo">
                    <div className="pc-div">
                      {imageSources.map((src, index) => (
                        <a
                          key={index}
                          href={pdfLink[index]}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label={`View PDF for Image ${index + 1}`}
                        >
                          <img
                            className="pc-image"
                            key={index}
                            src={src}
                            alt="image loading..."
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                  
    "/images/home-page/ISO_45001-Logo.webp",
    "/images/home-page/ISO_14001_UKAS_2014.webp",
    "/images/home-page/ISO_9001_UKAS_2014_TCL_HR.webp",
    "/images/home-page/00011.webp", */}
                  <div className="pc-logo">
                    <div className="pc-div">
                      {/* <a
                        href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/sc_cb-gss_GSS-GRS-20212865_20241102.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PDF for Image 1"
                      >
                        <img
                          className="pc-image"
                          src="/images/home-page/GRS.png"
                          alt="ISO 9001 Logo"
                          style={{ marginRight: "29px" }}
                        />
                      </a> */}
                      <a
                        href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-45001-BPPL20_0296.00-2.en-1-1.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PDF for Image 2"
                      >
                        <img
                          className="pc-image"
                          src="/images/home-page/ISO_45001-Logo.webp"
                          alt="ISO 45001 Logo"
                          style={{ marginRight: "5px" }}
                        />
                      </a>
                      <a
                        href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/14001-2022.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PDF for Image 3"
                      >
                        <img
                          className="pc-image"
                          src="/images/home-page/ISO_14001_UKAS_2014.webp"
                          alt="ISO 14001 Logo"
                          style={{ marginRight: "5px" }}
                        />
                      </a>
                      <a
                        href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-9001-2015_2022.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PDF for Image 4"
                      >
                        <img
                          className="pc-image"
                          src="/images/home-page/ISO_9001_UKAS_2014_TCL_HR.webp"
                          alt="ISO 9001 Logo"
                          style={{ marginRight: "5px" }}
                        />
                      </a>
                      <a
                        href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/OC-ECO-Spindles-Private-Limited.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PDF for Image 5"
                      >
                        <img
                          className="pc-image"
                          src="/images/home-page/00011.webp"
                          alt="Oeko-Tex Logo"
                        />
                      </a>
                    </div>
                  </div>

                  <center>
                    <div className="mobile-logo">
                      {/* <div className="mobile-fourth-div">
                        <a
                          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/sc_cb-gss_GSS-GRS-20212865_20241102.pdf"
                          target="_blank"
                          rel=""
                          aria-label=""
                        >
                          <img
                            className="mobile-fourth-div-image"
                            src="/images/home-page/GRS.png"
                            alt="image loading..."
                          />
                        </a>
                      </div> */}

                      <div className="mobile-first-div">
                        <a
                          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-45001-BPPL20_0296.00-2.en-1-1.pdf"
                          target="_blank"
                          rel=""
                          aria-label=""
                        >
                          <img
                            src="/images/home-page/ISO_45001-Logo.webp"
                            alt="image loading..."
                            style={{ maxWidth: "200px", marginLeft: "20px" }}
                          />
                        </a>
                      </div>

                      <div className="mobile-second-div">
                        <a
                          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/14001-2022.pdf"
                          target="_blank"
                          rel=""
                          aria-label=""
                        >
                          <img
                            className="mobile-second-div-image"
                            src="/images/home-page/ISO_14001_UKAS_2014.webp"
                            alt="image loading..."
                          />
                        </a>
                      </div>

                      <div className="mobile-third-div">
                        <a
                          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/ISO-9001-2015_2022.pdf"
                          target="_blank"
                          rel=""
                          aria-label=""
                        >
                          <img
                            className="mobile-third-div-image"
                            src="/images/home-page/ISO_9001_UKAS_2014_TCL_HR.webp"
                            alt="image loading..."
                          />
                        </a>
                      </div>

                      <div className="mobile-fifth-div">
                        <a
                          href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/OC-ECO-Spindles-Private-Limited.pdf"
                          target="_blank"
                          rel=""
                          aria-label=""
                        >
                          <img
                            className="mobile-fifth-div-image"
                            src="/images/home-page/00011.webp"
                            alt="image loading..."
                          />
                        </a>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </center>
                  <EmailForm />
                </div>
              </div>
            </div>
            <div className="column">
              {/* Column 2 */}
              <div className="s1-para-2">
                <div className="pc">
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <video
                  className="home-page-second-video"
                  // autoPlay
                  // loop // Add this attribute for looping
                  controls // Add this attribute for playback controls
                  muted // Add this attribute to enable auto-play in some browsers
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/eco_spindles_sri_lanka.webm"
                  alt="loading..."
                />
                <div className="pc">
                  <img
                    className="home-page-second-vid-img"
                    src="/images/home-page/dots.png"
                    alt="loading..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>

      {/* session - 2 */}
      <div className="background-scroll-container">
        {/* <div
          ref={backgroundRef}
          className={`scroll-background-image-1 ${
            backgroundInView ? "fadeInLeft" : ""
          }`}
        ></div> */}
        <div className="scroll-background-image-1"></div>
        <div className={`content-sus-img1 ${imageInView ? "fadeInLeft" : ""}`}>
          <img
            ref={imageRef}
            className="imageone"
            alt="image loading..."
            src="/images/home-page/home_-world-problem-02.webp"
          />
          <div className="s2-italic-font">
            <p className="s2-font">
              *Source:{" "}
              <a href="https://www.theworldcounts.com/stories/Bottled_Water_Waste_Facts">
                The World Counts
              </a>
              ,{" "}
              <a href="https://www.considerable.com/home/cleaning-organizing/plastic-bottle-recycling-caps/">
                Considerable
              </a>
              ,{" "}
              <a href="https://tappwater.co/us/how-many-people-consume-bottled-water-globally/">
                Tappwater
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* session - 3 */}
      <center>
        <div className="page-content">
          <div className="table-container">
            <div className="table-row">
              <div className="table-column1">
                <img
                  className="table-column1-img"
                  src="/images/home-page/ICON_01-01.webp"
                  alt="image loading..."
                />
              </div>
              <div className="table-column2">
                <h2 className="topic2">
                  2.15 MILLION TONNES OF PLASTIC WASTE IS MISMANAGED EVERY YEAR
                  IN SRI LANKA
                </h2>
                <p className="para">
                  Through education of the populace and an investment in
                  sustainable technologies, Eco Spindles takes the problem of
                  plastic pollution head-on. The impact will largely be unseen,
                  but we are proud of our efforts.
                </p>
                <div className="table-column2-div">
                  <p className="s3-font">
                    *Source:{" "}
                    <a href="https://www.lk.undp.org/content/srilanka/en/home.html">
                      UNDP Sri Lanka
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>

      {/* session - 4 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-2"></div>
        <div className="content-sus">
          <div className="green-box-2">
            <center>
              <div className="topic-container">
                <div className="horizontal-line left"></div>
                <div>
                  <p className="topic-text">PROBLEM</p>
                </div>
                <div className="horizontal-line right"></div>
              </div>
              <p className="home-s4-para">
                PLASTIC WASTE ENDANGERS WILDLIFE AND POLLUTES THE ENVIRONMENT
              </p>
            </center>
          </div>
        </div>
      </div>

      {/* session - 5 */}
      <div
        className={`session5 ${session5InView ? "fadeInUp" : "false"}`}
        ref={session5}
      >
        <center>
          <div className="page-content">
            <div className="table-container-s5">
              <div className="table-row-s5">
                <center>
                  <div className="table-column1-s5">
                    <img
                      className="table-column1-s5-img"
                      loading="eager"
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/recycle-01.png"
                      alt="image loading..."
                    />
                  </div>
                </center>
                <div className="table-column2-s5">
                  <h2 className="topic">
                    ECO SPINDLES INITIATES LOCAL SOLUTIONS TO FIGHT A GLOBAL
                    PROBLEM
                  </h2>
                  <p className="para">
                    Making recycling easy and beneficial is a major step to
                    sustainability. Our studies found that Sri Lanka lacks such
                    infrastructure, which led to major work in creating one.
                  </p>
                </div>
              </div>
            </div>

            <center>
              <img
                className="process_homepage-01-img"
                loading="eager"
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/home/process_homepage-01.jpg"
                alt="image loading..."
              />
            </center>
          </div>
        </center>
      </div>

      {/* session - 6 - pc */}
      <div className="pc-2">
        <div className="background-scroll-container">
          <div loading="eager" className="scroll-background-image-3"></div>
          <div className="content-sus-2-mobile">
            <div className="content-sus-2">
              <div className="card-background-1-pc">
                <div className="card-background-pc">
                  <div className="card-grid-pc">
                    <div className="card-pc">
                      <img
                        loading="eager"
                        src="/images/home-page/ES_ICONS-01-1.svg"
                        alt="image loading..."
                      />
                      <h2 className="card-topic">OVER 600</h2>
                      <p className="card-para">
                        COLLECTION POINTS AND
                        <br />
                        19 BAILING SITES
                      </p>
                    </div>

                    <div className="card-pc">
                      <img
                        loading="eager"
                        src="/images/home-page/ES_ICONS-02.svg"
                        alt="image loading..."
                      />
                      <h2 className="card-topic">9 TONNES</h2>
                      <p className="card-para">
                        COLLECTED AND SENT TO
                        <br />
                        FACTORIES DAILY
                      </p>
                    </div>

                    <div className="card-pc">
                      <img
                        loading="eager"
                        src="/images/home-page/ES_ICONS-03.svg"
                        alt="image loading..."
                      />
                      <h2 className="card-topic">250 TONNES</h2>
                      <p className="card-para">
                        OF PET IS RECYCLED
                        <br />
                        EACH MONTH
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* session - 6 - mobile */}
      <div className="mobile-2">
        <div className="background-scroll-container">
          <div loading="eager" className="scroll-background-image-3"></div>
          <div className="content-sus-2-mobile">
            <div className="content-sus-2">
              <div>
                <div className="card-grid">
                  <div className="card">
                    <img
                      loading="eager"
                      src="/images/home-page/ES_ICONS-01-1.svg"
                      alt="image loading..."
                    />
                    <h2 className="card-topic">OVER 600</h2>
                    <p className="card-para">
                      COLLECTION POINTS AND 19 BAILING SITES
                    </p>
                  </div>

                  <div className="card">
                    <img
                      loading="eager"
                      src="/images/home-page/ES_ICONS-02.svg"
                      alt="image loading..."
                    />
                    <h2 className="card-topic">9 TONNES</h2>
                    <p className="card-para">
                      COLLECTED AND SENT TO FACTORIES DAILY
                    </p>
                  </div>

                  <div className="card">
                    <img
                      loading="eager"
                      src="/images/home-page/ES_ICONS-03.svg"
                      alt="image loading..."
                    />
                    <h2 className="card-topic">250 TONNES</h2>
                    <p className="card-para">OF PET IS RECYCLED EACH MONTH</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* session - 7 */}
      <div className="session-7-div">
        <center>
          <div className="page-content-3">
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ width: "97%" }}>
                  <h2 className="topic">
                    DEVELOPING A SUSTAINABLE FINANCIAL MODEL FOR PET COLLECTION
                  </h2>
                </div>
                <p className="para">
                  Eco Spindles dedicates itself to sustainability, and that
                  dedication includes the lives of thousands of people across
                  the country. We provide opportunities to those who are
                  committed to creating a beautiful Sri Lanka.
                </p>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div className="column-2-div">
                  <ParaSlider />
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* session - 8 */}
      <div className="background-scroll-container">
        <div loading="eager" className="scroll-background-image-4"></div>
        <div className="content-sus-3">
          <div className="content-sus-3-div">
            <center>
              <div className="page-content">
                <div className="s8-para">
                  <center>
                    <div className="s8-para-first-div">
                      <h2 className="topic" id="wasteConversionLabel">
                        CONVERTING WASTE TO
                        <br />
                        VALUABLE PRODUCTS
                      </h2>
                    </div>
                    <div className="s8-para-second-div">
                      <p className="para">
                        We give plastic waste a second life efficiently by using
                        cutting edge recycling technology.
                        <br />
                        This reduces environmental impact and results in high
                        quality products.
                      </p>
                    </div>

                    <div className="ban-gap">
                      <div className="icon-container">
                        <div className="icon-column" id="column1">
                          <a href="/products-filament/" aria-label="button">
                            <div loading="eager" className="button-image-1" />
                          </a>
                          <a
                            href="/products-filament/"
                            aria-label="button"
                            className="icon-text"
                          >
                            <h2 className="icon-text"> MONOFILAMENTS</h2>
                          </a>
                        </div>
                        <div className="icon-column" id="column2">
                          <a href="/product-yarn/" aria-label="button">
                            <div loading="eager" className="button-image-2" />
                          </a>
                          <a
                            href="/product-yarn/"
                            aria-label="button"
                            className="icon-text"
                          >
                            <h2 className="icon-text">YARN</h2>
                          </a>
                        </div>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>

      {/* session - 9 */}
      <div>
        <center>
          <div className="page-content">
            <center>
              <div className="page-content-first-div">
                <div className="page-content-second-div">
                  <h2 className="topic">
                    OUR UP-SCALED RECYCLED PRODUCTS ARE
                    <br /> EXPORTED AROUND THE WORLD
                  </h2>
                  <p className="para">
                    Our partners recognize and appreciate our fully auditable
                    and transparent recycling process. The effort we put into
                    traceability and maximizing recycling efficiency makes it
                    easy for eco-conscious brands to invest in sustainability.
                  </p>
                </div>
              </div>
            </center>

            <div className="page-content-third-div">
              <Logos1 />
            </div>
          </div>
        </center>
      </div>

      {/* session - 10 */}
      <div id="form-section">
        <center>
          <div className="page-content">
            <center>
              <div className="page-content-first-div">
                <div className="page-content-second-div">
                  <h2 className="topic3">
                    TOGETHER, WE TAKE SRI LANKA TOWARDS
                    <br /> SUSTAINABILITY
                  </h2>

                  <div className="s10-card-grid">
                    <a
                      href="/ocean-waste-management/"
                      className="link"
                      aria-label="button"
                    >
                      <div
                        className="s10-card"
                        style={{
                          backgroundImage:
                            'url("/images/home-page/closeup-green-sea-turtle-swimming-underwater-lights-1.webp")',
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <center>
                          <div className="s10-card-content">
                            <h2 className="s10-card-title">
                              COASTAL PLASTIC
                              <br />
                              WASTE MANAGEMENT
                            </h2>
                            <p className="s10-card-p">See Initiative</p>
                          </div>
                        </center>
                      </div>
                    </a>

                    <a
                      href="/pilgrimage-waste-management/"
                      className="link"
                      aria-label="button"
                    >
                      <div
                        loading="eager"
                        className="s10-card"
                        style={{
                          backgroundImage:
                            'url("/images/home-page/pilgramige1.webp")',
                        }}
                      >
                        <center>
                          <div className="s10-card-content">
                            <h2 className="s10-card-title">
                              PILGRIMAGE WASTE
                              <br />
                              MANAGEMENT
                            </h2>
                            <p className="s10-card-p">See Initiative</p>
                          </div>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </center>
          </div>
        </center>
      </div>

      {/* session - 11 */}
      <div>
        <center>
          <div className="page-content">
            <center>
              <div className="page-content-first-div">
                <div className="page-content-second-div">
                  <div className="s11-3rd">
                    <div className="m-t-f">
                      <h2 className="topic">PARTNERSHIPS & COLLABORATIONS</h2>
                    </div>
                    <p className="para">
                      We invest heavily into bringing local players to the
                      recycling practice. By involving country-wide
                      organizations in our efforts, we ensure that
                      sustainability receives plenty of attention and backing to
                      support the eco effort even at the lowest levels.
                    </p>
                  </div>
                </div>
              </div>
            </center>

            <div className="page-content-third-div">
              <Logos2 />
            </div>
          </div>
        </center>
      </div>

      <Footer />
    </div>
  );
}
